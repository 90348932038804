var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth__complete-profile fill-height"},[_c('AuthWrapper',{staticClass:"auth__complete-profile",attrs:{"fields":{
      firstName: _vm.firstName,
      lastName: _vm.lastName,
      phoneDial: _vm.phoneDial,
      phoneValue: _vm.phoneValue,
      professions: _vm.professions,
      acceptNewsletters: _vm.acceptNewsletters
    },"title-text":_vm.$t('commons.sentences.motion-analysis-software'),"subtitle-text":_vm.$t('commons.sentences.you-are-almost-there'),"action-text":_vm.$t('commons.actions.validate'),"errors":[_vm.doctorService.state.value.context.completingError],"is-loading":_vm.isLoading,"is-disabled":_vm.isDisabled,"is-errored":_vm.isErrored},on:{"submit":function($event){return _vm.submit()}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('VRow',[_c('VCol',{attrs:{"cols":"12"}},[_c('FormFieldText',{attrs:{"field":_vm.firstName,"label":((_vm.$t('commons.standards.first-name')) + " *"),"autocomplete":"given-name","autofocus":"","disabled":""}})],1),_c('VCol',{attrs:{"cols":"12"}},[_c('FormFieldText',{attrs:{"field":_vm.lastName,"label":((_vm.$t('commons.standards.last-name')) + " *"),"autocomplete":"family-name","disabled":""}})],1),_c('VCol',{attrs:{"cols":"12"}},[_c('FormFieldPhone',{attrs:{"field-number":_vm.phoneValue,"field-dial":_vm.phoneDial,"label":_vm.$tc('commons.standards.phone')}})],1),_c('VCol',{attrs:{"cols":"12"}},[_c('FormFieldSelectChips',{attrs:{"field":_vm.professions,"label":((_vm.$tc('commons.standards.profession', 2)) + " *"),"disabled":_vm.isDisabled}})],1),_c('VCol',{attrs:{"cols":"12"}},[_c('FormFieldSelect',{attrs:{"field":_vm.unit,"label":((_vm.$tc('settings.doctor.config.unit')) + " *"),"disabled":_vm.isDisabled,"item-text":"text"}})],1),_c('VCol',{attrs:{"cols":"12"}},[_c('FormFieldCheckbox',{attrs:{"field":_vm.acceptNewsletters,"label":_vm.$t('commons.actions.auth.accept-newsletters'),"disabled":_vm.isDisabled}})],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }