
















































































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, toRef } from '@vue/composition-api';
import { phone } from 'phone';
// Import plugins ----------------------------------------------------------------------------------
import { Professions } from '@/plugins/podocore/modules/doctor/doctor.module';
// Import utils ------------------------------------------------------------------------------------
import {
  useFormObserver,
  useFormFieldText,
  useFormFieldSelectChips,
  useFormFieldAutoComplete
} from '@/utils/forms.utils';
import { useUnit } from '@/utils/unit.utils';
// Import components -------------------------------------------------------------------------------
import AuthWrapper from '@/components/auth/AuthWrapper.vue';
import FormFieldText from '@/components/forms/FormFieldText.vue';
import FormFieldPhone from '@/components/forms/FormFieldPhone.vue';
import FormFieldSelectChips from '@/components/forms/FormFieldSelectChips.vue';
import FormFieldCheckbox from '@/components/forms/FormFieldCheckbox.vue';
import FormFieldSelect from '@/components/forms/FormFieldSelect.vue';
// Import types ------------------------------------------------------------------------------------
import { EUnit } from '@/plugins/podocore/repositories/doctor.repository';
import type { TUnit } from '@/utils/unit.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'AuthCreateProfile',
  components: {
    AuthWrapper,
    FormFieldText,
    FormFieldPhone,
    FormFieldSelectChips,
    FormFieldCheckbox,
    FormFieldSelect
  },
  props: {
    doctorService: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const observer = useFormObserver();

    const { supportedUnits } = useUnit();

    const firstName = useFormFieldText<string>({
      key: 'firstName',
      value: properties.doctorService.state.value.context.doctor.profile.firstName,
      rules: {
        required: true,
        min: 2,
        max: 50
      }
    });

    const lastName = useFormFieldText<string>({
      key: 'lastName',
      value: properties.doctorService.state.value.context.doctor.profile.lastName,
      rules: {
        required: true,
        min: 2,
        max: 50
      }
    });

    const _phone = phone(properties.doctorService.state.value.context.doctor.phone, {
      validateMobilePrefix: false
    });

    const phoneDial = useFormFieldText<string>({
      key: 'phone.dial',
      value: _phone.isValid ? _phone.countryCode.replace('+', '') : '1',
      rules: {
        required: true
      }
    });

    const phoneValue = useFormFieldText<string>({
      key: 'phone.number',
      value: _phone.isValid ? _phone.phoneNumber.replace(_phone.countryCode, '') : '',
      rules: {
        phone: toRef(phoneDial, 'value')
      }
    });

    const professions = useFormFieldSelectChips<Professions>({
      key: 'professions',
      value: [],
      items: Object.values(Professions),
      rules: {
        required: true,
        min: 2,
        max: 50
      }
    });

    // TODO : replace by correct component (select)
    const unit = useFormFieldAutoComplete<string, TUnit>({
      key: 'unit',
      value: EUnit.Metric,
      items: supportedUnits,
      rules: {
        required: true
      }
    });

    const acceptNewsletters = useFormFieldText<boolean>({
      key: 'acceptDpa',
      value: false,
      rules: {
        required: true
      }
    });

    function submit() {
      const data: any = {
        profile: {
          professions: professions.value
        },
        newsletters: {
          consented: acceptNewsletters.value
        },
        config: {
          unit: unit.value
        }
      };

      if (phoneDial.value && phoneValue.value) {
        data.phone = `+${phoneDial.value}${phoneValue.value}`;
      }

      properties.doctorService.send({
        type: 'COMPLETE',
        data
      } as any);
    }

    const isErrored = computed(() =>
      [{ completing: 'failure' }].some((element) => properties.doctorService.state.value.matches(element))
    );

    const isDisabled = computed(() =>
      [{ completing: 'processing' }, 'challenged', 'success'].some((element) =>
        properties.doctorService.state.value.matches(element)
      )
    );

    const isLoading = computed(() =>
      [{ completing: 'processing' }].some((element) => properties.doctorService.state.value.matches(element))
    );

    return {
      // Form observer
      observer,
      // Form fields
      firstName,
      lastName,
      phoneDial,
      phoneValue,
      professions,
      unit,
      acceptNewsletters,
      // Actions
      submit,
      // Flags
      isErrored,
      isDisabled,
      isLoading
    };
  }
});
